import React from 'react'
import { graphql } from 'gatsby'

import InfoSection from 'components/InfoSection'
import Layout from 'components/Layout'
import NavigationTips from 'components/NavigationTips'

const Index = ({ data }) => (
  <Layout
    pageTitle='Hem'
    headerImageText={
      <h2 className='header__image-text'>
        Vår vision är att
        <br />
        göra våra kunder till bättre företagare!
      </h2>
    }
    headerBackgroundImage={data.header.childImageSharp.gatsbyImageData}
  >
    <oma-grid-row contentAlignedWithGrid='true'>
      <section className='section'>
        <h1 className='page__heading'>
          Vill du bli en bättre företagare, låt oss hjälpa dig!
        </h1>
        <div className=''>
          <p className='section__text'>
            Det gör vi genom att göra det svårbegripliga lättförståeligt och att
            förenkla våra kunders vardag. Vi utför kvalificerade redo-
            visningstjänster och rådgivning i ekonomiska frågor för mindre och
            medelstora företag. Vi anpassar våra tjänster helt utifrån kundens
            behov. Det kan vara enstaka kvitton som behöver bokföras eller en
            hel ekonomiavdelning som skall skötas, vilket vi också kan göra på
            plats ute hos kunden. Vi lyssnar och lär känna våra kunder för att
            kunna se helheten och ge den bästa rådgivningen.
          </p>
          <p className='section__text'>
            <strong>
              Vill du veta mer och träffa oss, hör av dig så bokar vi ett möte.
              Ring <oma-link to='+4633414535' /> eller maila{' '}
              <oma-link to='info@ertan.se' />
            </strong>
          </p>
        </div>
      </section>
      <NavigationTips />
    </oma-grid-row>
    <InfoSection />
  </Layout>
)

export const query = graphql`
  {
    backgrounds: allFile(
      filter: { relativeDirectory: { eq: "headers/index" } }
    ) {
      nodes {
        relativePath
        childImageSharp {
          gatsbyImageData(quality: 70, layout: FULL_WIDTH)
        }
      }
    }
    header: file(relativePath: { eq: "headers/index/00.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 70, layout: FULL_WIDTH)
      }
    }
  }
`

export default Index
