import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";

const NavigationTips = () => {
  const { about, services, cases } = useStaticQuery(graphql`{
  about: file(relativePath: {eq: "tjej-kontor.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 500, height: 400, quality: 90, layout: CONSTRAINED)
    }
  }
  services: file(relativePath: {eq: "vara-tjanster.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 500, height: 400, quality: 90, layout: CONSTRAINED)
    }
  }
  cases: file(relativePath: {eq: "agab2.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 500, height: 400, quality: 90, layout: CONSTRAINED)
    }
  }
}
`)

  return (
    <section className='section navigation-tips'>
      <Link className='navigation-tips__item' to='/om-oss'>
        <GatsbyImage image={about.childImageSharp.gatsbyImageData} alt='Om oss-image' />
        <h3 className='navigation-tips__title'>Om oss</h3>
      </Link>
      <Link className='navigation-tips__item' to='/vara-tjanster'>
        <GatsbyImage
          image={services.childImageSharp.gatsbyImageData}
          alt='Våra tjänster-image' />
        <h3 className='navigation-tips__title'>Våra tjänster</h3>
      </Link>
      <Link className='navigation-tips__item' to='/kundcase'>
        <GatsbyImage image={cases.childImageSharp.gatsbyImageData} alt='Kundcase-image' />
        <h3 className='navigation-tips__title'>Kundcase</h3>
      </Link>
    </section>
  );
}

export default NavigationTips
